import { GroupReservation } from '../pages/newReservations/resController';

export enum ReservationInvoicedStatus {
    notPaid = 0,
    fullyPaid = 1,
    partialPayed = 2,
}

export enum GroupInfoStatus {
    active = 1,
    inactive = 0,
}

export enum CashRegPaymentsEnum {
    payIn = 0,
    payOut = 1,
}

export enum ReservationStatus {
    earlyBird = 0,
    confirmed = 1,
    checkedIn = 2,
    canceled = 3,
    closed = 4,
    autoBlocked = 5,
}

export enum ReservationStatusForCleaning {
    unknown = 0,
    noReservation = 1,
    arrival = 2,
    dueOut = 3,
    stay = 4,
}

export enum InvoiceType {
    geniue = 0,
    advance = 1,
    proforma = 2,
    proformaDispatched = 3,
}

export enum AccommodationTaxPrefix {
    accommodationTax = 'Accommodation tax',
}

export enum HotelUserRole {
    superAdmin = 'superAdmin',
    admin = 'admin',
    condoUser = 'condoUser',
    hwAdmin = 'hwAdmin',
    janitor = 'janitor',
    maid = 'maid',
    managerBussiness = 'managerBussiness',
    managerFull = 'managerFull',
    managerHousehold = 'managerHousehold',
    managerSecurity = 'managerSecurity',
    recepcionist = 'recepcionist',
    reporting = 'reporting',
    security = 'security',
    sos = 'SOS',
    posKitchen = 'posKitchen',
    posBartender = 'posBartender',
    posSupervisor = 'posSupervisor',
    hwLANView = 'hwLANView',
    offlinePosApi = 'offlinePosApi',
    gdprOfficer = 'gdprOfficer',
}

export interface CustomerModel {
    id?: number;
    documentId?: string | null;
    documentType?: string | null;
    cardNumber?: string | null;
    firstName: string | null;
    lastName: string | null;
    email?: string | null;
    gender?: string | null;
    dateOfBirth?: Date | null;
    birthTown?: string | null;
    birthCountry?: string | null;
    poNumber?: string | null;
    address?: string | null;
    town?: string | null;
    country?: string | null;
    nationality?: string | null;
    phoneOrMobile?: string | null;
    params?: any | null;
    preferredLanguage?: string | null;
    otherLanguage?: string | null;
    gdprBasicConsent: boolean;
    companyId?: number | null;
    documentScanId?: number | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
    emailHash?: string | null;
}

export interface CompanyModel {
    id?: number;
    name: string;
    idNumber?: string | null;
    address?: string | null;
    poNumber?: string | null;
    town?: string | null;
    country?: string | null;
    notes?: string | null;
    web?: string | null;
    params?: any | null;
    oib?: string | null;
    taxFree?: boolean;
    procurementEmail?: string | null;
    salesEmail?: string | null;
    administrativeEmail?: string | null;
    techSupportEmail?: string | null;
}

export interface RoomInfoModel {
    id?: number;
    uuid?: string | null;
    name: string;
    remoteDeviceId?: string | null;
    roomPhone?: string | null;
    srcProxyId?: number | null;
    tags?: string | null;
    description?: string | null;
    accommodationPriceListId: number | null;
    roomAttributes?: string | null;
    SRCSettings?: any | null;
    availableForRent?: boolean;
    bedCount: number;
    spareBedCount: number | null;
    posItemIds?: string | null;
    params?: string | null;
    cleaningModeActivatedTs?: number | null;
    roomHWDefaults?: any;
    autoPrecomfortMode?: boolean;
    mfcAccessBitMask?: number;
    activeCardCode?: number;
    remoteDeviceId2?: string | null;
    remoteDeviceId3?: string | null;
}

export interface RoomInfoEditModel extends RoomInfoModel {
    id: number;
}

export interface ReservationModel {
    id?: number;
    checkOutTimestamp: number;
    checkInTimestamp: number;
    additionalServicesJson?: any | null;
    statusEnum: ReservationStatus;
    autoBlockCount?: number | null;
    earlyBirdValidTo?: number | null;
    statusChangeNotes?: string | null;
    notes?: string | null;
    adultsNumber: number;
    childrenNumber?: number | null;
    rememberedAccommodationPriceInfo?: PriceOffer[];
    enableSelfCheckIn: boolean | null;
    guest1CodeCopy?: number | null;
    guest2CodeCopy?: number | null;
    guest3CodeCopy?: number | null;
    documents?: string | null;
    receptionistUuid?: string | null;
    //groupReservationStatus - ovo izbrisati
    discountPercentage?: number | null;
    pendingSelfCheckIn?: boolean;
    accommodationPriceListId: number | null;
    tags?: string | null;
    pendingQRSelfCheckIn?: boolean;
    pendingHardwareSelfCheckIn?: boolean;
    groupUuid: string | null;
    roomInfoId: number;
    customerId?: number | null;
    companyId?: number | null;
    invoicedStatus: number;
    createdAt?: Date;
    updatedAt?: Date;
    previousGroupUuid?: string | null;
    providerReservation?: boolean;
    condoUserUuid?: string | null;
    genuineInvoiceIssued?: number;
    openPrice: number | null;
    canceledReservationInvoicingStatus?: number;
    concludedType?: number | null;
    board: string;
}

export interface PriceOffer {
    accommodationPriceListId: number | null;
    checkInTimestamp: number;
    checkOutTimestamp: number;
    adultsNumber: number;
    childrenNumber?: number | null;
    priceOfferIntervals: PriceOfferInterval[];
    timestamp?: number;
    openPrice: number | null;
    statusEnum?: number;
    canceledReservationInvoicingStatus?: number;
    id?: number;
    board: string;
}

export interface PriceListShort {
    pluBase: number | null;
}

export interface PriceOfferInterval {
    from: string;
    to: string;
    fromToDays: number;
    apRules: number[];
    id?: number;
    priceList: PriceListShort;
    taxPercentage1?: number;
    taxPercentage2?: number;
    taxPercentage3?: number;
    taxFixedAmount?: number;
}

export interface PriceOfferIntervalsGroupByReservationId {
    [key: string]: PriceOfferInterval[];
}

export interface ReservationEditModel extends ReservationModel {
    id: number;
}

export interface ReservationWithMfcSql extends ReservationModel {
    mfcAccessBitMask: number;
}
export interface CustomerEditModel extends CustomerModel {
    id: number;
}

export interface CompanyEditModel extends CompanyModel {
    id: number;
}

export interface GroupInfoModel {
    uuid: string;
    note?: string | null;
    discountPercentage?: number | null;
    status: GroupInfoStatus;
    invoicedStatus: ReservationInvoicedStatus;
    receptionistUuid?: string | null;
    checkOutTimestamp: number | null;
    checkInTimestamp: number | null;
    customerId?: number | null;
    companyId?: number | null;
    createdAt?: Date;
    updatedAt?: Date;
    groupName?: string;
    genuineInvoiceIssued?: number;
    type: string;
}

export interface GroupInfoWithReservations extends GroupInfoModel {
    Reservations: ReservationModelWithModelsIncluded[];
}
export interface GroupInfoWithModelsIncluded extends GroupInfoModel {
    Invoices?: InvoiceModelWithInvoiceItems[];
    Customer?:CustomerEditModel
    Company?:CompanyEditModel
    Reservations?: ReservationModelWithModelsIncluded[];

}
export interface ReservationGuestReferenceModel {
    id?: number;
    customerId: number;
    reservationId: number;
    documentValidityPeriod?: string | null;
    documents?: string | null;
    isLocked?: boolean; //WTF ???
    note?: string | null;
    placeEntry?: string | null;
    visaEntryDate?: string | null;
    visaTypeAndNumber?: string | null;
    visaValidityPeriod?: string | null;
    applyAccommodationTax?: boolean;
    fixedTaxId?: number;
    documentId?: string; //dok se napravi sve maknut ?
    documentType?: string; //dok se napravi sve maknut ?
    checkOutTimestamp?: number | null;
    checkInTimestamp?: number | null;
    customerUuid?: string | null;
    paymentCategory?: string | null;
}

export interface ReservationGuestReferenceEditModel extends ReservationGuestReferenceModel {
    id: number;
}

export interface ReservationGuestReferenceWithModelsIncluded extends ReservationGuestReferenceModel {
    Customer?: CustomerModel;
}

export interface SelfServiceCheckInModel {
    id?: number;
    uuid?: string;
    pinCode: number | null;
    selfCheckInStatus?: string | null;
    selfCheckInValidFrom: number;
    selfCheckInValidTo: number;
    sendSelfCheckInCodeAfterTimestamp: number;
    notificationSent?: boolean;
    notificationIds?: string | null;
    consumptionTime?: number | null;
    issuedRoomCardCode?: number | null;
    gatewayNotificationStatus?: string | null;
    selfCheckInType: string;
    notificationLanguage: string | null;
    reservationId?: number;
}

export interface SelfServiceCheckInModelWithIncludedReservationModel extends SelfServiceCheckInModel {
    Reservation: ReservationModelWithModelsIncluded;
}
//provjerit sta moze bit null i sta ne mora nuzno bit u modelu
export interface DoorAccessPointModel {
    id?: number;
    remoteDeviceId: string;
    srcProxyId: number;
    serialPort: string | null;
    portConfigId: number;
    tags?: string | null;
    name: string;
    description?: string | null;
    whitelistTagCSV?: string | null;
    params?: string | null;
    lastSeenTimestamp: number | null;
    qrCode: string | null;
    messageAfterSuccessfullScan?: string | null;
    documentScannerDeviceReference?: string | null;
    roomTagWhitelistCSV?: string | null;
}

export interface UserInfo {
    uuid: string;
    name: string;
    surname: string;
    email: string;
    role: HotelUserRole;
    enabled: boolean;
    cardNumber: string;
    level: number;
    oib: string;
    condoRoomInfoIds: any[];
    customerId: number;
    condoUserPersonalDaysPerYear: number;
    settings: any;
    workingHours?: number | null;
    keycloakUuid?: string | null;
    lastLoginTimestamp?: number | null;
}

export interface MinibarLogModel {
    id?: number;
    previousState: number;
    newState: number;
    logType: number;
    parentId?: number | null;
    recordedAt?: number | null;
    roomInfoId?: number | null;
    posItemId: number;
    userUUID?: string | null;
    params?: string | null;
    reservationId?: number | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface PosItemModel {
    id?: number;
    barcode?: string | null;
    plu: number;
    type?: string | null;
    subtype?: string | null;
    name: string;
    description?: string | null;
    category?: string | null;
    image?: string | null;
    thumbnail?: string | null;
    enabled?: boolean | null;
    tags?: string | null;
    unitMeasure?: string | null;
    price: number;
    taxId: number;
    tax2Id?: number | null;
    tax3Id?: number | null;
    params?: string | null;
    composites?: string | null;
    flexiblePrice?: boolean | null;
    subcategory?: string | null;
    isComboItem?: boolean;
    createdAt?: Date | null;
    updatedAt?: Date | null;
    preparationRequired: boolean | null;
    normative?: boolean;
    suplements?: string | null;
    relatedArticlesIds?: number[];
    normativeRetailPriceTotal?: number;
    additionalMargin?: number;
    specialOffer?: boolean;
    specialOfferValidFrom?: number | null;
    specialOfferValidTo?: number | null;
    imageHash?: string | null;
    // showCounterOnInvoice?: boolean;
}

export interface PosItemEditModel extends PosItemModel {
    id: number;
}

export interface SelfCheckInLogModel {
    id?: number;
    timestamp: number;
    reservationId: number;
    description?: string;
    methodName?: string | null;
    methodSuccessfullyPassed?: boolean;
    errorMessage?: string | null;
    params?: string | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface NotificationTemplateModel {
    id?: number;
    uniqueName: string;
    type?: string | null;
    title?: string | null;
    body?: string | null;
    isEnabled?: boolean;
    params?: string | null;
    useHtmlTemplate?: boolean;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface CleaningStatusHeaderModel {
    id?: number;
    note: string;
    validOnTimestamp: number;
}

export interface CleaningStatusHeaderEditModel extends CleaningStatusHeaderModel {
    id: number;
}

export interface CleaningStatusModel {
    id?: number;
    cleaningStatus: number;
    reservationId: number | null;
    reservationStatus: number;
    cleaningStartedTimestamp: number | null;
    cleaningFinishedTimestamp: number | null;
    cleaningNote: string | null;
    cleanedByUserUuid: string | null;
    cleanedByUserUuid2: string | null;
    cleanedByUserUuid3: string | null;
    user1SrcEncoded: boolean | null;
    user2SrcEncoded: boolean | null;
    user3SrcEncoded: boolean | null;
    cleaningStatusHeaderId: number | null;
    roomInfoId: number | null;
    inspectionStatus: number | null;
    inspectionNote: string | null;
}

export interface CleaningStatusEditModel extends CleaningStatusModel {
    id: number;
}

export interface CleaningStatusExtendedWithIncludedModels extends CleaningStatusModel {
    Maid1: UserInfo | undefined;
    Maid2: UserInfo | undefined;
    Maid3: UserInfo | undefined;
    RoomInfo: RoomInfoEditModel | undefined;
}

export interface CleaningStatusHeaderExtendedWithCleaningStatusModel extends CleaningStatusHeaderEditModel {
    CleaningStatuses: CleaningStatusExtendedWithIncludedModels[];
}

export interface RoleModel {
    key: string;
    value: string | null;
    isActive: boolean;
    params: string | null;
    sessionDurationInMinutes: number | null;
    dashboardRoute: string | null;
    fixedRoute: string | null;
    externalDashboardRoute: string | null;
    externalFixedRoute: string | null;
}

export interface PermissionModel {
    id?: number;
    name: string;
    description: string | null;
    targetId: number | null;
}

export interface RoleWithPermissions extends RoleModel {
    Permissions: PermissionModel[];
}

export interface PermissionRoleModel {
    PermissionId: number;
    RoleKey: string;
}

export interface TaxModel {
    id?: number;
    percentageAmount: number | null;
    fixedAmount?: number | null;
    name: string;
    plu?: number | null;
    isActive?: boolean | null;
    params?: string | null;
    isGeneralTax: boolean;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface AccommodationPricelistModel {
    id?: number;
    parentId: number | null;
    name: string;
    description: string | null;
    tags: string | null;
    pluBase: number | null; //null za pocetak
    weight: number;
    infinite: boolean;
    validFrom: number | null;
    validTo: number | null;
    blockPromotions: boolean;
    repeatRules: string | null | any;
    accomodationPriceRules: string | number[];
    discountRules: string | null;
    isActive: boolean;
    isDeleted: boolean;
    condoPricelist: boolean;
    params?: string;
    // generalTaxCode: number | null; // mislim da je suvisno
    // pricePerChild: number | null;
    boards: any;
    defaultBoard: string | null;
}

export interface ChannelManagerMap {
    id?: number;
    provider: string;
    model: string;
    remoteId: string | null;
    remoteUuid: string | null;
    modelId: string | null;
    modelUuid: string | null;
    tags: string | null;
    roomPriceValidTo: number | null;
    remoteName: string | null;
    data: string | null;
    roomInfoIds: number[];
    parentId?: string | null;
    vpType?: number | null;
    vpValue?: number | null;
}

export interface CashRegistarModel {
    id?: number;
    name: string;
    currency: string | null;
    posTerminalId: number | null;
    cashMax: number | null;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface CashRegistarPaymentPurposeModel {
    id?: number;
    name: string;
    type: number;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface CashRegistarPaymentsModel {
    id?: number;
    paymentNumber?: number;
    date: number;
    amount: number;
    currency: string | null;
    OIB?: string | null;
    description?: string | null;
    customerId: number | null;
    companyId: number | null;
    paymentPurposeId: number;
    createdAt?: Date;
    updatedAt?: Date;
    invoiceId: number | null;
    cashRegistarReportId: number;
    currencyExchangeRate: number;
}

export interface CashRegistarReportModel {
    id?: number;
    reportNumber?: number;
    fromTimestamp: number;
    toTimestamp: number | null;
    previousState: number;
    payIn: number;
    payOut: number;
    newState: number;
    total: number;
    cashRegistarId: number;
    createdAt?: Date | string;
    updatedAt?: Date | string;
}

// export interface CashRegPaymentsExtendedModel extends CashRegistarPaymentsModel, CustomerModel, CompanyModel {}

export interface CashRegReportEditModel extends CashRegistarReportModel {
    id: number;
}

export interface CurrencyModel {
    id?: number;
    shortName: string;
    name: string;
    params?: string;
    isEnabled: boolean;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface ReservationWithIncludedModels extends ReservationEditModel {
    RoomInfo: RoomInfoModel;
    Customer: CustomerModel;
    Company: CompanyModel;
}

export interface MinibarLogWithIncludedModels extends MinibarLogModel {
    RoomInfo: RoomInfoModel;
    PosItem: PosItemModel;
    //  InvoiceItem: any; //sad za sad
}

export interface MinibarLogExtended extends MinibarLogModel {
    posItemName: string;
    roomName: string;
}

export interface InitialAndlatestMinibarLogWithIncludedModels {
    filterEnabledPosItemsMinibarLogs: MinibarLogExtended[];
    initialfilterEnabledPosItemsMinibarLogs: MinibarLogExtended[];
}
export interface InvoiceItemsModel {
    id?: number;
    bruttoUnitPrice: number | null;
    bruttoValueWithDiscount: number | null;
    discountPercentage: number | null;
    discountValue: number | null;
    group: string | null;
    invoiceId: number | null;
    itemName: string;
    minibarLogId: number | null;
    nettoUnitPrice: number | null;
    nettoValueWithDiscount: number | null;
    plu: number | null;
    posItemId: number | null;
    quantity: number | null;
    recordedAt: number | null;
    tax1ValueWithDiscount: number | null;
    tax2ValueWithDiscount: number | null;
    tax3ValueWithDiscount: number | null;
    taxIncludedInPrice: boolean;
    taxPercentage1?: number | null;
    taxPercentage2?: number | null;
    taxPercentage3?: number | null;
    totalTaxValue: number | null;
    type: string;
    unitPrice: number | null;
    valueWithDiscount: number | null;
    taxFreeCompany?: boolean;
    subtype?: string | null;
    //Samo za potrebe financijskog izvjesca prodaje - nije u bazi spremljeno
    generalDiscountPercentage?: number | null;
    //Samo za potrebe kalkulacija - nije u bazi spremljeno
    bruttoUnitsValue?:number
    brutoValueDiscount?:number

}

export interface AdvancePaymentsInterface {
    id: number;
    itemName: string;
    invoiceNumber: string;
    paymentAmount: number;
    typeOfPayment: string;
}

export interface InvoiceModel {
    id?: number;
    advancePayments: AdvancePaymentsInterface[];
    advancePaymentsValue: number | null;
    checkInTimestamp: number;
    checkOutTimestamp: number;
    companyId: number | null;
    companyInfo: string | null;
    connectedInvoiceProcessed: number | null;
    connectedInvoices: number[];
    currency: string;
    currencyExchangeRate: number;
    customerId: number | null;
    customerInfo: string | null;
    defaultCurrency: string;
    deliveredValue: number | null;
    exchangeRates: string | null;
    fiscal: number;
    fiscalId: number | null;
    fiscalSRCProxy: string | null;
    generalDiscountPercentage: number;
    generalDiscountValue: number;
    invoiceNumber: number | null;
    invoiceType: number;
    invoiceValue: number | null;
    issuerCompanyInfo: string | null;
    issuerSecurityCode: string | null;
    locale: string;
    note: string | null;
    operatorName: string | null;
    orderNumber: number | null;
    params: string | null;
    partialPayment: boolean;
    paymentAmount: number | null;
    pendingInvoice: number;
    posTerminalId: number | null;
    posTerminalName: string | null;
    reclamation: number;
    reclamationFiscalId: number | null;
    reclamationReason: string | null;
    refInvoice: number | null;
    groupUuid: string | null;
    reservationId: number | null;
    businessSpaceLabel: string | null;
    sfJIR: string | null;
    tollDeviceLabel: string | null;
    taxIncludedInPrice: boolean;
    taxRecapitulation: string | null;
    terminalType: string;
    timestamp: number | null;
    totalBaseVAT: number | null;
    totalBaseVAT_BD: number | null;
    totalBaseValue: number | null;
    totalBaseValue_BD: number | null;
    totalLineItemDiscountValue: number | null;
    totalVAT: number | null;
    totalValue: number | null;
    typeOfPayment: string | null;
    vatRegistered: boolean;
    createdAt?: Date;
    updatedAt?: Date;
    InvoiceItems?: InvoiceItemsModel[];
    receivedPayment?: number;
    paymentStatus?: number;
    invoiceNumberFull: string | null;
    taxFreeCompany?: boolean;
    operatorUuid: string | null;
    posOrderHeaderIds: number[];
    invoiceConclusionId?: number;
    stockDespatched?: boolean;
    externalInvoiceNumber: number | null;
    stockDocHeaderId: number | null;
    isSyncedWithMaster?: boolean | null;
    advanceTimestamp: number | null;
    invoiceOfflineId?: number | null;
}

export interface InvoiceModelWithInvoiceItems extends InvoiceModel {
    InvoiceItems: InvoiceItemsModel[];
}

export interface InvoiceControlerModel {
    invoice: InvoiceModelWithInvoiceItems;
    invoiceItems: InvoiceItemsModel[];
    advanceInvoices: InvoiceModelWithInvoiceItems[];
    proformaInvoices: InvoiceModelWithInvoiceItems[];
    stornoInvoices: InvoiceModelWithInvoiceItems[];
    posTerminalInvoices: InvoiceModelWithInvoiceItems[];
    genuineInvoices: InvoiceModelWithInvoiceItems[];
    checkoutDateMismatchs: number[];
    minibarInvoices: InvoiceModelWithInvoiceItems[];
    availableForAdvancePayment: number;
}

export interface InvoicePreviewObject {
    reservationId: number;
    customerInfo: string;
    companyInfo: string;
    checkInTimestamp: number;
    checkOutTimestamp: number;
    params: string;
}

export interface InvoicePreview {
    invoice: InvoicePreviewObject;
    invoiceItems: InvoiceItemsModel[];
}

export interface InvoiceBaobabModel extends InvoiceControlerModel {
    reservation: ReservationEditModel | null;
    groupReservationsArray: ReservationModelWithModelsIncluded[];
    stayoverOverlap: StayOverOverlap;
    invoicePreview?: InvoicePreview;
    invoiceStornoPreview: InvoiceModelWithInvoiceItems | null;
    accommodationInvoice: InvoiceModelWithInvoiceItems | null;
}

export interface StayOverOverlap {
    overlapReservations: ReservationModelWithModelsIncluded[];
    option: number | null;
    type: string | null;
}

export interface AccommodationOffer {
    invoiceItems: InvoiceItemsModel[];
    priceOfferByLinkedIntervals: PriceOfferInterval[] ;
}

export interface AccommodationOfferGroup {
    invoiceItems: InvoiceItemsModel[];
    priceOfferByLinkedIntervals: PriceOfferIntervalsGroupByReservationId ;
}

export interface PosTerminalModel {
    id?: number;
    name: string;
    isEnabled: boolean;
    terminalType: number;
    fiscalInfo: string | FiscalInfoInterface;
    warehouseId?: number | null;
    params: string;
    tableLayoutIds?: string | null;
    mandatoryTableSelection?: boolean;
    connectedPosTerminals?: string | number[];
    parentPosTerminalId: number | null;
    enableOrderSave?: boolean;
    enableAutoLogout?: boolean;
    uuid?: string | null;
    isOfflinePOSTerminal?: boolean;
}

export enum PosTerminalType {
    checkoutTerminal = 0,
    posInterfaceTerminal = 1,
}

export interface FiscalInfoInterface {
    srcProxyName?: string;
    SRCProxyUUID?: string;
    businessSpace?: string;
    tollDevice?: string;
    printToPos?: boolean;
    charLength?: number;
}

export interface CustomerInfoModel {
    documentId?: string;
    firstName?: string;
    lastName?: string;
    address?: string;
    poNumber?: string;
    town?: string;
    country?: string;
    type?: string;
}

export interface CompanyInfoModel {
    idNumber?: string;
    oib?: string | null;
    name?: string;
    address?: string;
    poNumber?: string;
    town?: string;
    country?: string;
    type?: string;
}

export interface FiscalGeneralSettings {
    enableFiscalisation: boolean;
    country: string;
}
export interface RoomInfoMap {
    [key: string]: RoomInfoEditModel;
}
export interface ReservationModelWithModelsIncluded extends ReservationEditModel {
    RoomInfo?: RoomInfoEditModel;
    Customer?: CustomerEditModel;
    Company?: CompanyEditModel;
    GroupInfo?: GroupReservation;

    Invoices?: InvoiceModelWithInvoiceItems[]; // nakon merge ima invoice model
    MinibarLogs?: MinibarLogModel[];
    ReservationGuestReferences?: ReservationGuestReferenceModel[];
    VisaInfo?: ReservationGuestReferenceModel[];
    Guests?: CustomerModel[];
    SelfServiceCheckIn?: SelfServiceCheckInModel;
}

export interface IssuerCompanyInfo {
    name: string;
    hotelName: string;
    address: string;
    postOfficeNumber: string;
    town: string;
    contactEmail: string;
    idNumber: string;
    notes: string;
}

export interface InvoicePresenter {
    invoice: InvoiceModelWithInvoiceItems;
    invoiceItems: InvoiceItemsModel[];
    refInvoice?: InvoiceModelWithInvoiceItems | null;
    invoicePreview?: InvoicePreview;
    groupReservationsArray?: ReservationModelWithModelsIncluded[];
    stornoInvoices?: InvoiceModelWithInvoiceItems[];
    groupInvoiceDetails?: GroupInvoiceDetails;
    translate?: any;
    location?: any;
    print?: boolean;
    padding?: number;
    setInvoiceToSplit?: Function;
}

export interface GroupInvoiceDetails {
    [key: string]: InvoicePreview;
}

export interface TaxRecapitulation {
    base: number;
    taxValue1: number;
    taxValue2: number;
    taxValue3: number;
    totalTaxValue: number;
    taxPercentage1: number | null;
    taxPercentage2: number | null;
    taxPercentage3: number | null;
    taxGroup: string;
}
export interface MessageBrokerInformationModel {
    id?: number;
    channelDriver: string;
    timestamp: number;
    senderInfo: string;
    receiverInfo: string;
    subject: string;
    message: string;
    model?: string;
    modelId?: number;
    statusFlag: number | null;
    notBeforeTimestamp: number | null;
    rules?: string;
    params?: string;
}
export interface MessageBrokerLogModel {
    id?: number;
    request: string;
    response: string;
    timestamp: number;
    remoteReference?: string | null;
    messageBrokerInformationId: number;
}

export interface CalculatedInvoiceLineItem {
    unitPrice: number;
    quantity: number;
    nettoUnitPrice: number;
    bruttoUnitsValue: number;
    brutoValueDiscount: number;
    nettoValueWithDiscount: number;
    valueWithDiscount: number;
    bruttoUnitPrice: number;
    bruttoValueWithDiscount: number;
    tax1ValueWithDiscount: number;
    tax2ValueWithDiscount: number;
    tax3ValueWithDiscount: number;
    totalTaxValue: number;
    discountValue: number;
    discountPercentage: number;
    taxPercentage1: number;
    taxPercentage2: number;
    taxPercentage3: number;
    type?: string | null | undefined;
    itemName?: string | null;
    plu?: number | null;
}

export interface GeneralSettingModel {
    uuid: string;
    key: string;
    params: string;
}
export interface ReservationReportHeaderModel {
    id?: number;
    reportNumber: number;
    fromTimestamp: number;
    toTimestamp: number | null;
    userUuid?: string | null;
    date?: string;
}

export interface ReservationReportItemsModel {
    id?: number;
    reservationStatusEnum: number;
    persons: number;
    adults: number;
    children: number;
    days?: number;
    adultDays?: number;
    childDays?: number;
    accommodationTaxValue: number;
    accommodationTaxVAT?: number;
    reservationId: number;
    reservationHeaderId: number;
    warningNote?: string | null; //ovo se samo koristi za upozorenje za rezervacije koje su se trebale checkoutat
}

export interface ReservationReportGuestInfosModel {
    id?: number;
    firstName: string;
    lastName: string;
    gender: string | null;
    birthDate: Date | null;
    birthPlace?: string | null;
    birthCountry?: string | null;
    town?: string | null;
    country?: string | null;
    citizenship: string;
    checkInDate: number;
    checkOutDate: number;
    documentType: string | null; //??
    documentNumber: string | null;
    documentValidityPeriod?: Date | null;
    documentPublisher?: string | null;
    visaType?: string | null;
    visaNumber?: string | null;
    visaValidityPeriod?: Date | null;
    dateEntry?: Date | null;
    placeEntry?: string | null;
    phoneOrMobile?: string | null;
    email?: string | null;
    notes: string | null;
    resReportItemId: number | null;
    customerId: number | null; //ali mora inace bit
    resGuestReferenceId: number;
    foreignServiceUsername?: string | null;
    bookSerialNumber?: string | null;
    accTaxValuePerPeson: number | null;
    paymentCategory?: string | null;
    arrivalOrganisation?: string | null;
    customerUuid?: string | null;
    reservationStatusEnum?: number;
    markAsSend?: boolean;
}

export interface EStranacExportModel {
    //?? valjda je ovako, jer tako dobijem na primjeru exporta
    'Redni broj pod kojim je stranac zaveden u knjigu stranaca': number | string;
    'Serijski broj knjige stranaca u koju je unesen stranac': number | string;
    'Korisničko ime zaposlenog koji je izvršio prijavu stranca': string;
    'Ime stranca': string;
    'Prezime stranca': string;
    'Pol stranca': string;
    'Datum rođenja stranca': string;
    'Država rođenja stranca': string;
    'Mjesto rođenja stranca': string;
    'Državljanstvo stranca': string;
    'Vrsta putne isprave stranca': string;
    'Broj putne isprave stranca': number | string;
    'Rok važenja putne isprave stranca': string;
    'Izdavač putne isprave stranca': string;
    'Vrsta vize stranca': string;
    'Broj vize stranca': string;
    'Rok važenja vize stranca': string;
    'Datum ulaska u državu stranca': string;
    'Mjesto ulaska u državu stranca': string;
    'Broj izdatog fiskalnog računa za stranca': string;
    'Broj telefona stranca': string;
    'Email stranca': string;
    Napomena: string;
    'Datum prijave stranca u smještajnu jedinicu': string;
    'Datum odjave stranca iz smještajne jedinice': string;
    'Korisničko ime zaposlenog koji je izvršio odjavu stranca': string;
    ''?: string;
}

export interface EVisitorCheckOutModel {
    ID: string; // guid, identifikator prijave turista, ako je proslijeđen onda nije potrebno proslijeđivati parametre Facility, DocumentType i DocumentNumber
    Facility: string;
    DocumentType: string;
    DocumentNumber: string;
    CheckOutDate: number | string; // datum odjave turista (format: YYYYMMDD, primjer 20150413)
    CheckOutTime: string; // vrijeme odjave turista (format: hh:mm, primjer 09:42)
}
export interface EVisitorCheckInTouristModel {
    ID: string; //  guid, identifikator prijave turista, ako se proslijedi onda se može upotrebiti za izmjenu ili odjavu određene prijave turista
    Facility: string; //?number šifra objekta u koji se turist prijavljuje. Šifra objekta je objektu dana od sustava e-Visitor.
    StayFrom: number | string; //YYYYMMDD
    TimeStayFrom: string; //?string hh:mm
    ForeseenStayUntil: number | string; //YYYYMMDD
    TimeEstimatedStayUntil: string; //string hh:mm
    DocumentType: string;
    DocumentNumber: string;
    TouristName: string;
    TouristSurname: string;
    Gender: string;
    CountryOfBirth: string; // tro-slovna šifra države (ISO oznaka) rođenja (npr. HRV za Hrvatsku ili DEU za Njemačku)
    CityOfBirth?: string; // ukoliko je država rođenja Hrvatska, onda ovdje treba staviti naziv grada-naselja rođenja u obliku „grad-naselje“ iz pripremljenog šifrarnika, npr. „Zagreb-Adamovec“. Ukoliko država rođenja nije Hrvatska, onda ovdje treba biti upisan naziv grada iz te države s tim da trenutno sustav prihvaća slobodni unos
    DateOfBirth: number | string; // YYYYMMDD
    Citizenship: string; // tro-slovna šifra države (ISO oznaka) čije državljanstvo ima turist (npr. HRV za Hrvatsku ili DEU za Njemačku)
    CountryOfResidence: string; //tro-slovna šifra države (ISO oznaka) prebivališta (npr. HRV za Hrvatsku ili DEU za Njemačku)
    CityOfResidence: string; // ukoliko je država prebivališta Hrvatska, onda ovdje treba staviti naziv grada-naselja prebivališta u obliku „grad-naselje“ iz pripremljenog šifrarnika, npr. „Zagreb-Adamovec“. Ukoliko država prebivališta nije Hrvatska, onda ovdje treba biti upisan naziv grada iz te države s tim da trenutno sustav prihvaća slobodni unos
    ResidenceAddress?: string; // ulica i broj prebivališta
    BorderCrossing?: string; // šifra graničnog prijelaza (obavezno ukoliko je turist iz države koja nije članica EU)
    PassageDate?: number; // datum ulaska u EU (obavezno ukoliko je turist iz države koja nije članica EU)
    TTPaymentCategory: string; // ?number šifra kategorije plaćanja boravišne pristojbe
    ArrivalOrganisation: string; // - šifra (MUP) organizacije dolaska, vrijednost CodeMI iz browse-a ArrivalOrganisationLookup
    TouristAgency?: string; //?number - OIB ili VAT number turističke agencije, ukoliko je organizacija dolaska putem agencije. vrijednost iz browse-a TouristAgencyBrowse. Ukoliko ne postoji tražena agencija možete unijeti novu agenciju u sustav pomoću entity resursa TouristAgency Neobavezan podatak ukoliko ArrivalOrganisation je "Osoban". Ukoliko je "Agencijski" onda je TuristAgency obvezan podatak.
    OfferedServiceType: string; // naziv vrste pružene uslugeEditOfExistingCheckIn – opcionalni podatak. Ukoliko želite izmijeniti postojeću prijavu zbog greške u unosu onda u ovom polju šaljite vrijednost true. Samo aktivna prijava se može izmijeniti, a ključ za identifikaciju postojeće prijave (ukoliko nije proslijeđen ID parametar) su slijedeći podaci: DocumentType, DocumentNumber, DateOfBirth, CountryResidenceID, TouristName. Prilikom izmjene potrebno je slati sve podatke, a ne samo one koje želite izmjeniti.
}

export interface EVistiorExportModel extends EVisitorCheckInTouristModel {
    CheckOutDate: number | string; // datum odjave turista (format: YYYYMMDD, primjer 20150413)
    CheckOutTime: string; // vrijeme odjave turista (format: hh:mm, primjer 09:42)
}

export interface TableModel {
    id?: number;
    backgroundPosition: string | null;
    name: string | null;
    numberOfSeats: number | null;
    tablesLayoutId: number;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface TablesLayoutModel {
    id?: number;
    name: string;
    description?: string | null;
    backgroundImage?: string | null;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface PosDisplayConfigModel {
    id?: number;
    name: string;
    associatedUserUuids: string[] | null;
    associatedPrinterUuids: string[] | null;
    associatedTerminalIds: number[] | null;
    posSubscribedTags: string | null;
    type: string | null;
    isKitchen: boolean;
    createdAt?: Date;
    updatedAt?: Date;
    associatedPrinterPositions: number[] | null;
}

export interface PosOrderHeaderModel {
    id?: number;
    note?: string | null;
    statusEnum: string;
    startTimestamp: number;
    endTimestamp: number | null;
    tableId: number | null;
    customerId: number | null;
    companyId: number | null;
    invoiceId: number | null;
    createdAt?: Date;
    updatedAt?: Date;
    posTerminalId: number;
    createdByUser: string;
}
export interface AuthTokenInfo {
    token: string;
    user: UserInfo;
}

export interface ChannelManagerRoom {
    id?: number;
    name: string;
    occupancy: number;
    men: number;
    children: number;
    shortname: string;
    subroom: number;
    board: string;
    price: number;
    availability: number;
    woodoo: number;
    rtype: number;
    rtype_name: string;
    boards: string;
    anchorate: number;
    dec_avail: number;
    min_price: number;
    max_price: number;
}
export interface DebuggingLogModel {
    id?: number;
    timestamp: number;
    type: string;
    statusType: string | null;
    route: string | null;
    payload: string | null;
    message: string | null;
}

export interface PosTerminalConsumption {
    posItemId: number;
    quantity: number;
    newPrice: number | string; //12.02 Ovo ostavljamo samo na hologicu, na posFrontendu je samo broj
    type: string;
    itemName?: string;
    unitPrice?: number;
    total?: number;
    maxQuantity?: number;
    warehouseId?: number;
    roomInfoId?: number;
    maxTotal?: number;
    discountPercentage: number | null;
    group?: string | null;
}
export interface StockConsumption {
    posItemId: number;
    quantity: number;
    reservationId: number | null;
    roomInfoId: number | null;
    userUuid: string;
    warehouseId?: number;
    maxTotal?: number;
    group?: string | null;
}

export interface BillingSummaryHeader {
    id?: number;
    timestamp: number;
    companyInfo: string;
    note: string;
    defaultCurrency: string;
    currency: string;
    currencyExchangeRate: number;
    typeOfPayment: string;
    invoiceType: number;
    paymentAmount: number;
    exchangeRates: string;
    totalBaseValue_BD: number;
    totalBaseVAT_BD: number;
    totalLineItemDiscountValue: number;
    totalBaseValue: number;
    totalBaseVAT: number;
    generalDiscountPercentage: number;
    generalDiscountValue: number;
    totalValue: number;
    totalVAT: number;
    invoiceValue: number;
    taxIncludedInPrice: boolean;
    taxRecapitulation: string;
    params: string;
    locale: string;
    invoiceNumber: number;
    vatRegistered: boolean;
    paymentStatus: boolean;
    receivedPayment: number;
    invoiceNumberFull: string;
    taxFreeCompany: boolean;
    year: number;
    month: number;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}
export interface StockDocHeaderModel {
    id?: number;
    docNumber: string;
    type: number;
    date: number;
    commitedDate: number | null;
    itemsCount: number;
    itemsValue: number;
    createdByUser: string | null;
    commitedByUser: string | null;
    companyId: number | null;
    warehouseId: number | null;
    receivingWarehouseId?: number | null; // za medjuskladisnicu
    locked: boolean;
    currency: string | null;
    invoiceId?: number | null;
    conclusionType?: number | null; //1 manualni, 2 sistemski
    previousConclusion?: number | null;
    connectedStockDocHeader?: number | null;
    documentInvoiceNumber?: string | null;
    note?: string | null;
    params?: string | null;
    isSyncedWithMaster?: boolean;
}

export interface StockDocItemModel {
    id?: number;
    skuId: number;
    quantity: number;
    newState?: number;
    purchasePrice: number;
    taxId1: number | null;
    taxId2?: number | null;
    taxId3?: number | null;
    grossMargin?: number; //mora biti al racunam na backendu
    skuPrice: number;
    stockValue?: number | null;
    rebatePercentage?: number | null;
    stockDocHeaderId?: number | null; //mora ga biti al nemam ako tek dodajem header
    skuName: string;
    previousState?: number;
    totalGoodsReceived?: number;
    totalGoodsDespatched?: number;
    totalInovicedStock?: number;
    manualTotalInovicedStock?: number;
    totalCumulativeLoss?: number;
    inventoryRecord?: number;
    lockedItem: boolean;
    invoiceId?: number | null;
    invoiceItemId?: number;
    stockHeaderStockReportId?: number | null;
    priceForOfferNote?: number | null;
    params?: string | null;
    delete?: boolean;
    currentState?: number;
    parentId?: number | null;
    externalCode?: string | null;
}

export interface StockDocItemModelForStockReport {
    id?: number;
    skuId: number;
    quantity: number;
    newState?: number;
    purchasePrice: number;
    taxId1: number | null;
    taxId2?: number | null;
    taxId3?: number | null;
    grossMargin?: number; //mora biti al racunam na backendu
    skuPrice: number;
    stockValue: number | null;
    stockDocHeaderId: number; //mora ga biti al nemam ako tek dodajem header
    skuName: string;
    previousState: number;
    totalGoodsReceived: number;
    totalGoodsDespatched: number;
    totalInovicedStock: number;
    manualTotalInovicedStock: number;
    totalCumulativeLoss: number;
    inventoryRecord: number;
    lockedItem: boolean;
    invoiceItemId?: number;
    stockHeaderStockReportId?: number | null;
    params?: string | null;
    new?: boolean;
    currentState?: number;
}

export interface WarehouseModel {
    id?: number;
    name: string;
    type: number;
    description?: string | null;
    delayInvoiceStockDespatched: boolean;
}

export interface StockKeepingUnitModel {
    id?: number;
    unitType: number;
    barcode: string | null;
    code: string;
    name: string;
    description?: string;
    // measureCode: string;
    category: string | null;
    taxId1: number | null;
    taxId2?: number | null;
    taxId3?: number | null;
    salesPrice: number;
    grossMarginPercentage: string;
    calculateSalesPriceOnStockIncrease: boolean;
    averagePurchasePrice: number;
    active: boolean;
    stockMeasureUnit: string;
    purchaseMeasureUnit: string | null;
    purchaseMeasureUnit_stockUnitMultiplier: number | null;
    minimumStock: number;
    replenishStock: number;
    isCounter: boolean;
    cumulativeLoss: number;
    parentId?: number | null;
    params?: string | null;
}

export interface StockKeepingUnitEditModel extends StockKeepingUnitModel {
    id: number;
}

export interface SkuPLURateModel {
    id?: number;
    skuId: number;
    skuMeasureCode: string;
    pluMeasureCode: string;
    multiplier: number;
    posItemId?: number;
    description?: string | null;
    params?: string | null;
}
export interface EntryCalculationHeaderModel {
    id?: number;
    docNumber: string;
    date: number;
    commitedDate: number | null;
    newPriceValidFrom: number | null;
    createdByUser: string | null;
    commitedByUser: string | null;
    stockDocHeaderId: number | null;
    companyId: number | null;
    locked: boolean;
    currency: string | null;
    totalTransportation: number;
    totalShipping: number;
    totalCustoms: number;
    totalOtherCosts: number;
    totalInvoicePriceValue: number;
    calculationInvoiceNumber: string | null;
    invoiceId?: string | null;
    params?: string | null;
}

export interface EntryCalculationItemModel {
    id?: number;
    barcode: string | null;
    code: string;
    name: string;
    quantity: number;
    invoiceUnitPrice: number;
    invoicePriceValue: number;
    rebatePercentage: number;
    netoUnitPrice: number;
    netoPriceValue: number;
    transportation: number;
    shipping: number;
    customs: number;
    otherCosts: number;
    purchaseUnitPrice: number;
    purchasePriceValue: number;
    priceDifferencePercentage: number;
    wholesaleUnitPrice: number;
    wholesalePriceValue: number;
    tax1: number | null;
    tax2: number | null;
    tax3: number | null;
    tax1Total: number | null;
    tax2Total: number | null;
    tax3Total: number | null;
    retailUnitPrice: number;
    retailPriceValue: number;
    entryCalcHeaderId: number;
    skuId: number;
    skuPrice: number;
    params?: string | null;
    createdAt?: Date;
    updatedAt?: Date;
    delete?: boolean;
}

export interface StockLogModel {
    id?: number;
    quantity: number;
    dateTime: number;
    warehouseId: number;
    receivingWarehouseId?: number | null;
    skuId: number;
    invoiceId: number | null;
    invoiceItemId?: number;
    stockDocHeaderId: number | null;
    stockDocHeaderIdForReceiving?: number | null;
    type: number;
    params?: string | null;
    createdAt?: Date;
    updatedAt?: Date;
    roomInfoId?: number | null;
    userCreated: string | null;
    posItemId: number | null;
    multiplier: number;
    skuPluRatesCount: number;
    autoSuggestWriteOff?: boolean;
}

export interface PriceChangeLogHeaderModel {
    id?: number;
    docNumber: string;
    date: number;
    commitedDate: number | null;
    createdByUser: string | null;
    commitedByUser: string | null;
    newPriceValidFrom: number;
    currency: string | null;
    locked: boolean;
    warehouseId: number | null;
    note: string | null;
    params?: string | null;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface PriceChangeLogItemModel {
    id?: number;
    skuId: number;
    skuName: string;
    skuStockCurrent: number;
    previousSkuPrice: number;
    previousSkuPriceValue: number;
    newSkuPrice: number;
    newSkuPriceValue: number;
    taxAdjustment: number;
    priceAdjustment: number;
    priceChangeLogHeaderId: number;
    params?: string | null;
    createdAt?: Date;
    updatedAt?: Date;
    parentId?: number | null;
    delete?: boolean;
}

export enum SKUType {
    'beverage' = 1,
    'food' = 2,
    'other' = 3,
}

export const SKUTypeName = {
    1: 'beverage',
    2: 'food',
    3: 'other',
}

export enum WarehouseType {
    'consignmentWarehouse' = 1,
    'warehouse' = 2,
    'retailWarehouse' = 3,
    'householdWarehouse' = 4,
    'receptionWarehouse' = 5,
    'householdWarehouseMinibar' = 6,
}

export enum StockLogType {
    'invoice' = 1,
    'goodsDespatchedInvoice' = 2,
    'householdWriteOff' = 3,
    'goodsDespatchedHousehold' = 4,
    'householdRequiredPermission' = 5,
}

export interface EntryCalculationHeaderEditModel extends EntryCalculationHeaderModel {
    id: number;
}

export interface StockDocHeaderEditModel extends StockDocHeaderModel {
    id: number;
}

export interface SkuSupplierPriceListPerHeaderModel {
    id?: number;
    validFrom: number;
    companyId: number;
    locked: boolean;
    note?: string;
    params?: string | null;
}

export interface SkuSupplierPriceListItemModel {
    id?: number;
    price: number;
    previousPrice: number | null;
    preferedProcurementSku?: boolean;
    skuId: number;
    headerId: number;
    params?: string | null;
}

export interface SkuItemCodeToSupplierCodeModel {
    id?: number;
    code: string;
    skuId: number;
    companyId: number;
    params?: string | null;
}
export interface WarehouseStock {
    warehouseId: number;
    skuId: number;
    name: string;
    unitType: number;
    code: string;
    barcode: string | null;
    isCounter: boolean;
    skuPrice: number;
    stockMeasureUnit: string;
    minimumStock: number;
    replenishStock: number;
    taxId1: number | null;
    taxId2?: number | null;
    taxId3?: number | null;
    cumulativeLoss: number;
    previousState: number;
    totalGoodsReceived: number;
    totalGoodsDespatched: number;
    totalInovicedStock: number;
    manualTotalInovicedStock: number;
    totalCumulativeLoss: number;
    //jos ovo za potrebe inventure
    goodsReceiving?: number;
    currentState?: number;
    // stateWithGoodsReceiving?:number;
    newState?: number;
    stockQuantity?: number;
    stockPriceValue?: number;
    stockDocItemId?: number | null;
    inventoryRecord?: number;
    totalWriteOffGoods?: number;
    notInUseItem?: boolean;
}

export interface SkuSupplierPriceListPerHeaderEditModel extends SkuSupplierPriceListPerHeaderModel {
    id: number;
}

export interface StockDocItemsExtended extends StockDocItemModel {
    StockKeepingUnit: StockKeepingUnitModel;
    externalCode?: string;
    warehouseId?: number;
}

export interface StockDocItemsExtendedSql extends StockDocItemModel {
    itemId: number;
    name: string;
    unitType: number;
    code: string;
    barcode: string | null;
    isCounter: boolean;
    stockMeasureUnit: string;
    minimumStock: number;
    replenishStock: number;
    skuTax1: number | null;
    skuTax2: number | null;
    skuTax3: number | null;
    salesPrice: number;
    parentId: number | null;
    cumulativeLoss: number;
    externalCode?: string;
    warehouseId?: number;
}

export interface StockLogsExtendedSql extends StockLogModel {
    itemId: number;
    name: string;
    unitType: number;
    code: string;
    barcode: string | null;
    isCounter: boolean;
    stockMeasureUnit: string;
    minimumStock: number;
    replenishStock: number;
    skuTax1: number | null;
    skuTax2: number | null;
    skuTax3: number | null;
    salesPrice: number;
    parentId: number | null;
    cumulativeLoss: number;
}

export interface PriceChangeLogHeaderWithItems extends PriceChangeLogsHeadersExtended {
    PriceChangeLogItem?: PriceChangeLogItemsExtended[];
}

export interface PriceChangeLogItemsExtended extends PriceChangeLogItemModel {
    StockKeepingUnit: StockKeepingUnitModel;
}

export interface StockLogsExtended extends StockLogModel {
    StockKeepingUnit: StockKeepingUnitModel;
}

export interface StockDocItemsExtendedWithTaxes extends StockDocItemModel {
    StockKeepingUnit: StockKeepingUnitModel;
    tax1: TaxModel;
    tax2: TaxModel;
    tax3: TaxModel;
}

export interface StockKeepingUnitExtended extends StockKeepingUnitModel {
    tax1: TaxModel | null;
    tax2: TaxModel | null;
    tax3: TaxModel | null;
}

export interface EntryCalculationItemExtended extends EntryCalculationItemModel {
    StockKeepingUnit: StockKeepingUnitModel;
}

export interface StockDocHeadersExtended extends StockDocHeaderModel {
    Company: CompanyEditModel | null;
    CreatedByUser: UserInfo | null;
    CommitedByUser: UserInfo | null;
}

export interface PriceChangeLogsHeadersExtended extends PriceChangeLogHeaderModel {
    CreatedByUser: UserInfo | null;
    CommitedByUser: UserInfo | null;
}

export interface EntryCalcHeaderExtended extends EntryCalculationHeaderModel {
    CreatedByUser1: UserInfo | null;
    CommitedByUser1: UserInfo | null;
    StockDocHeader: StockDocHeaderEditModel | null;
    Company?: CompanyEditModel | null;
}

export interface StockDocHeadersWithItems extends StockDocHeaderModel {
    StockDocItems: StockDocItemsExtended[];
    Company: CompanyEditModel | null;
    CreatedByUser: UserInfo | null; //za template
    CommitedByUser: UserInfo | null;
    docEntryCalcId: number | null;
    goodsReceivingNote?: number | null;
    goodsDespatchNote?: number | null;
    interWarehouseTransfer?: number | null;
    previousConclusionDocNum?: string;
    nextConclusionId?: number;
    nextConclusionDocNum?: string;
}

export interface StockDocHeaderAndItems extends StockDocHeaderModel {
    StockDocItems: StockDocItemsExtended[];
}

export interface StockDocHeaderAndItemsSql extends StockDocHeaderModel {
    StockDocItems: StockDocItemsExtendedSql[];
}

export interface StockDocItemsAndHeader extends StockDocItemModel {
    StockDocHeader: StockDocHeaderEditModel;
    StockKeepingUnit: StockKeepingUnitModel;
}

export interface StockDocItemsAndHeaderSql extends StockDocItemModel {
    headerId: number;
    docNumber: string;
    type: number;
    // date: number;
    // commitedDate: number | null;
    // itemsCount: number;
    // itemsValue: number;
    // createdByUser: string | null;
    // commitedByUser: string | null;
    // companyId: number | null;
    warehouseId: number | null;
    receivingWarehouseId?: number | null;
    // locked: boolean;
    itemId: number;
    name: string;
    unitType: number;
    code: string;
    barcode: string | null;
    isCounter: boolean;
    stockMeasureUnit: string;
    minimumStock: number;
    replenishStock: number;
    skuTax1: number | null;
    skuTax2: number | null;
    skuTax3: number | null;
    salesPrice: number;
    cumulativeLoss: number;
    parentId: number | null;
}

export interface EntryCalcHeadersWithItems extends EntryCalcHeaderExtended {
    EntryCalculationItems: EntryCalculationItemExtended[];
}

export interface SkuPluRateWithItem extends SkuPLURateModel {
    StockKeepingUnit: StockKeepingUnitModel;
    PosItem?: PosItemModel;
}

export interface PosItemWithSkuId extends PosItemEditModel {
    skuId: number;
}

export interface SkuPluRateForForm extends SkuPLURateModel {
    StockKeepingUnit: StockKeepingUnitModel | null;
    PosItem?: PosItemModel | null;
    code: string;
    edit: boolean;
    delete?: boolean;
    normativeRetailPrice?: number;
    additionalMargin?: number;
}

export interface SupplierItemsExtended extends SkuSupplierPriceListItemModel {
    StockKeepingUnit: StockKeepingUnitModel;
    externalCode?: string;
}

export interface SupplierPriceListsExtended extends SkuSupplierPriceListPerHeaderModel {
    SkuSupplierPriceListItem: SupplierItemsExtended[];
    Company: CompanyEditModel;
}

export interface SkuItemToSupplierCodeExtended extends SkuItemCodeToSupplierCodeModel {
    StockKeepingUnit: StockKeepingUnitModel;
    externalCode?: string;
    previousPrice?: number;
}

export interface SkuItemSupplierCodeWithCompanyAndSkuItem extends SkuItemCodeToSupplierCodeModel {
    StockKeepingUnit?: StockKeepingUnitModel;
    Company: { id: number; name: string };
    lastPrice?: number;
}

export interface InventoryWithExistingAndOtherItems {
    existingSkuItems: WarehouseStock[];
    otherSkuItems: WarehouseStock[];
}

export enum StockDocumentTypesEnum {
    goodsReceivingNote = 1, // primitak
    goodsDespatchNote = 2, // otpis
    writeOffGoods = 3, // otpis
    interWarehouseTransfer = 4,
    procurementOrder = 5,
    offerNote = 6,
    invoicedStockNote = 7,
    stockReport = 8,
}

export const StockDocument = {
    1: 'goodsReceivingNote',
    2: 'goodsDespatchNote',
    3: 'writeOffGoods',
    4: 'interWarehouseTransfer',
    5: 'procurementOrder',
    6: 'offerNote',
    7: 'invoicedStockNote',
    8: 'stockReport',
};

export enum StockReportConclusionType {
    manual = 1,
    system = 2,
}

export const Operations: any = {
    addition: 'addition', // primka
    substraction: 'substraction', // otpremnica
    additionAndSubstraction: 'additionAndSubstraction',
};

export const GoodsRecevingOrDespatch: any = {
    goodsReceivingNote: Operations.addition, // primitak
    goodsDespatchNote: Operations.substraction, // otpis
    writeOffGoods: Operations.substraction, // otpis
    interWarehouseTransfer: Operations.additionAndSubstraction,
    invoicedStockNote: Operations.substraction,
};

export interface SRCProxyModel {
    id?: number;
    SRCProxyUUID: string;
    name: string;
    description: string;
    address: string;
    apiKey: string;
    printerProxy: boolean;
    lastSeenTimestamp: number;
    printerLineCharLength: number;
    isSyncedWithMaster?: boolean | null;
    offlineId?: number | null;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface InvoiceConclusionModel {
    id?: number;
    timestamp: number;
    previousInvoiceConclusionId: number | null;
    parentInvoiceConclusionId: number;
    operatorUuid: string | null;
    operatorName: string | null;
    shiftManager: string | null;
    note: string | null;
    cashRegistarReportId: number | null;
    cashDeposit: number | null;
    cashWithdrawal: number | null;
    fiscalConclusionRef: number | null;
    posTerminalId: number;
    posTerminalName: string | null;
    isPending: number;
    InvoiceConclusionItems?: InvoiceConclusionItemModel[];
    stockDocHeaderId: number | null;
}

export interface StockHeaderEnums {
    [key: number]: string;
}

export interface CellsWidth {
    [key: number]: number;
}

export interface HiddenCols {
    [key: string]: string[];
}

export interface UnitOfMeasure {
    [key: string]: string;
}

export interface SheetChange {
    x: number;
    y: number;
    value: string | number | null;
    skuItem?: StockKeepingUnitModel;
    posItem?: PosItemModel;
    glAccount?: GLAccountModel;
    company?: CompanyEditModel;
    customInput?: boolean;
    customOption?: string | number;
}

export interface SelectOptionAndValue {
    label: string;
    value: string;
    skuId?: number;
}

export interface SupplierPriceListToSheet {
    internalSkuCode: string | null;
    internalSkuBarcode: string | null;
    skuItemName: string | null;
    supplierSkuCode: string | null;
    previousPrice: number | null;
    supplierProcurementPrice: number;
    skuId: number | null;
    dataIndex?: number | null;
}

export interface SRCTemplateModel {
    id?: number;
    name: string | null;
    description: string | null;
    SRCSettings: string | null | any; //any moze biti model SRCSettingsModel
}

export interface PosTerminalEditModel extends PosTerminalModel {
    id: number;
}

export interface SmartRoomControllerModel {
    id: number;
    srcProxyId: number;
    remoteDeviceId: string;
    name: string;
    serialPort: string | null;
    portConfigId: number;
    lastSeenTimestamp: number;
    srcType: string;
    sendNotificationAboutFailure: boolean;
    lastTh1Readout: number | null;
    lastTh2Readout: number | null;
    lastTh3Readout: number | null;
    thReadoutTimestamp: number | null;
    SRCIOStates?: number | null;
    firmwareVersion: string | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface SrcCardCodeModel {
    id?: number;
    cardCode: number;
    cardCodeValidTo: number;
    type: string;
    roomInfoId: number;
    statusEnum: number;
    failedAttempts: number;
    lastUpdatedBy: string | null;
    remoteDeviceId: string;
    params?: string | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface SrcCardCodeExtendedModel extends SrcCardCodeModel {
    RoomInfo: RoomInfoEditModel;
}

export interface SRCTemplateModel {
    id?: number;
    name: string | null;
    description: string | null;
    SRCSettings: string | null | any; //any moze biti model SRCSettingsModel
}

export interface CardCodeManagementEnums {
    [key: number]: string;
}

export interface InvoiceConclusionItemModel {
    id?: number;
    invoiceType: number;
    cash: number | null;
    card: number | null;
    cheque: number | null;
    virman: number | null;
    proformaDispatched: number | null;
    cashTotal: number | null;
    nonCashTotal: number | null;
    total: number | null;
    totalTaxValue: number | null;
    invoiceCount: number | null;
    stornoInvoiceCount: number | null;
    taxRecapitulation: TaxRecapitulation[];
    invoiceConclusionId: number | null;
    clientType: number | null;
}

export interface PosTerminalPosItemModel {
    id?: number;
    posTerminalId: number;
    posItemId: number;
    description?: string | null;
    price: number;
    topArticle: boolean;
    createdAt?: Date;
    updatedAt?: Date;
}
export interface InvoiceItemPosPresenter {
    posItemId: number;
    itemName: string;
    quantity: number;
    unitPrice: number;
    bruttoUnitPrice: number;
    bruttoUnitsValue: number;
}

export interface AttributesModel {
    id?: number;
    name: string;
    description: string | null;
    params: string | null;
    icon: string | null;
}

export interface PersistentNotificationSystemModel {
    id?: number;
    key: string;
    title: string;
    message: string;
    severityEnum: number;
    timestamp: number;
    operatorUuid?: string; // u slučaju da korisnik
    operatorName?: string; // može upravljati notifikacijama
    isActive: boolean;
    targetRoles?: string | string[];
    linkToPage?: string;
}

export interface TaskManagementHeaderModel {
    id?: number;
    name: string;
    validFrom: number;
    validTo: number;
    taskHeaderGroup: string;
    roomInfoId: number | null;
    reservationId: number | null;
    notes?: string | null;
    params?: string | null;
}

export interface TaskHistory {
    startTimestamp: number;

    endTimestamp: number;
}

export interface TaskManagementItemModel {
    id?: number;
    taskContent: string | null;
    taskStatus: number | null;
    startTimestamp?: number | null;
    endTimestamp?: number | null;
    taskGroup: string;
    parentId: number | null;
    userArguments: string | null;
    roomInfoId: number | null;
    reservationId?: number | null;
    reservationIds: string | null;
    customerId: number | null;
    companyId: number | null;
    taskManagementHeaderId: number | null;
    userCreated: string | null;
    userFinished: string | null;
    notes?: string | null;
    params?: string | null;
    createdAt?: Date;
    updatedAt?: Date;
    taskTitle: string | null;
    taskTimeEstimate: number | null;
    userAssigned: string | null;
    totalTaskDuration: number | null;
    inspectionFailedCount?: number;
    userSrcEncoded: number;
    taskHistory: TaskHistory[];
    timestamp: number | null; // datum kreiranja
    roomStatuses: string | null;
    // samo za frontend
    subTasks?: TaskManagementItemModel[];
}

export interface TaskManagementHeaderEditModel extends TaskManagementHeaderModel {
    id: number;
}

export interface TaskManagementItemEditModel extends TaskManagementItemModel {
    id: number;
    timestamp: number;
}

export interface TaskManagementHeaderWithTaskItems extends TaskManagementHeaderEditModel {
    TaskManagementItems: TaskManagementItemEditModel[];
}

export enum TaskManagementStatusEnum {
    noStatus = 0,
    pending = 1,
    finished = 2,
    rejected = 3,
}

export enum TaskManagementGroups {
    cleaningStatus = 'cleaningStatus',
    lostAndFound = 'lostAndFound',
    roomRepair = 'roomRepair',
}

export interface GLAccountModel {
    account: string;
    name: string;
    companyId?: number | null;
    createdAt?: Date;
    updatedAt?: Date;
    showItem?: boolean;
    delete?: boolean;
}

export interface LineValue {
    credit: number;
    debt: number;
    balance: number;
    creditBalance: number;
    debtBalance: number;
    openingCredit: number;
    openingDebt: number;
}

export interface GLReportLineModel {
    id?: number;
    parentId: number | null;
    glReportId: number;
    lineName: string;
    lineDisplayOrder: number;
    glAccountsList: string[];
    expression?: string | null;
    columnReference?: string | null;
    numberOfSpecChar?: number | null;
    params?: string | null;
    lineValue?: LineValue;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface GLReportModel {
    id?: number;
    name: string;
    aggregationType: number;
    balanceRule: number | null;
    params?: string;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface GlDocumentModel {
    id?: number;
    docNumber: string;
    module?: string;
    name: string;
    type: number | null;
    class: number | null;
    postingDate: number;
    issueDate: number;
    dueDate: number;
    userUuid: string;
    isLocked: boolean;
    isDeleted: boolean;
    companyId: number | null;
    externalNumber?: string;
    credit: number;
    debt: number;
    note?: string | null;
    params?: string;
    createdAt?: Date;
    updatedAt?: Date;
    User?: UserInfo;
    Company?: CompanyEditModel | null;
    currencyId?: number | null;
    exchangeRate?: number | null;
}

export interface GeneralLedgerModel {
    id?: number;
    glDocumentId: number;
    companyId: number | null;
    account: string;
    credit: number;
    debt: number;
    description: string | null;
    reservationId: number | null;
    invoiceId: number | null;
    params?: string;
    createdAt?: Date;
    updatedAt?: Date;
    name?: string;
    surname?: string;
    creditForeign?: number;
    debtForeign?: number;
}

export interface GLReportItemModel {
    id?: number;
    openingCredit: number;
    openingDebt: number;
    credit: number;
    debt: number;
    cumulativeCredit: number;
    cumulativeDebt: number;
    creditBalance: number;
    debtBalance: number;
    account: string;
    glReportHeaderId: number;
    params?: string;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface GLReportHeaderModel {
    id?: number;
    reportForMonth: number;
    reportForYear: number;
    glReportId: number;
    isLocked: boolean;
    params?: string | null;
    createdAt?: Date;
    updatedAt?: Date;
    name?: string;
    aggregationType?: number;
    GLReport?: GLReportModel;
}

export interface GeneralLedgerExtended extends GeneralLedgerModel {
    GLAccount: GLAccountModel;
    Company?: CompanyEditModel | null;
    delete?: boolean;
}

export interface GlDocumentWithItems extends GlDocumentModel {
    GeneralLedger: GeneralLedgerExtended[];
}

export interface GeneralLedgerWithGLDocument extends GeneralLedgerModel {
    GLDocument: GlDocumentModel;
}

export interface GlDocumentWithItemsSql {
    account: string;
    credit: number;
    debt: number;
}

export interface GlReportLineExtended extends GLReportLineModel {
    GLReport: GLReportModel;
}
export interface GlReportWithLines extends GLReportModel {
    GLReportLines: GLReportLineModel[];
}

export interface GLReportItemExtended extends GLReportItemModel {
    GLAccount: GLAccountModel;
}

export interface GLReportHeaderWithItems extends GLReportHeaderModel {
    GLReportItems: GLReportItemExtended[];
}

export interface GroupedGLReportItemsSql {
    id?: number;
    name?: string;
    account: string;
    credit?: number;
    debt?: number;
    cumulativeCredit?: number;
    cumulativeDebt?: number;
    reportForYear?: number;
    reportForMonth?: number;
}

export interface EntryValueForSelectedPeriod extends GLReportItemModel {
    lastId: number;
    account: string;
}

export interface GlDocumentSql {
    id: number;
    glDocumentId: number;
    name: string | null;
    account: string;
    postingDate: number;
    docNumber: string;
    type: string;
    class: string;
    credit: number;
    debt: number;
    company?: string;
    accountName?: string;
    companyId?: number;
}

export interface AutoGLBookingModel {
    id?: number;
    code: string;
    documentName: string;
    documentItem: string;
    description: string | null;
    glType: number;
    glClass: number;
    creditGLAccount: string;
    debtGLAccount: string;
    predefinedValue: boolean; //uvijek FALSE s frontenda
    isBeingUsedFlag: boolean;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface TypeAndClassModel {
    id?: number;
    type: number;
    name: string;
    shortName: string;
    params?: any;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface GroupedGeneralLedger {
    [key: string]: GeneralLedgerModel;
}

export enum GLReportDocType {
    'closingState' = 1,
    'sum' = 2,
}

export enum TypeAndClassEnum {
    'type' = 1,
    'class' = 2,
}

export const TypeAndClassEnumText: any = {
    1: 'type',
    2: 'class',
};

export const GLReportDocTypeName: any = {
    1: 'closingState',
    2: 'sum',
};

export enum AutoGLBookingGoodsManagement {
    goodsReceivingNote = 1,
    goodsDespatchNote = 2,
    writeOffGoods = 3,
    interWarehouseTransfer = 4,
    procurementOrder = 5,
    offerNote = 6,
    invoicedStockNote = 7,
    stockReport = 8,
}

export enum AutoGLBookingRetailCalculation {
    retailPriceCalculation = 'retailPriceCalculation',
}

export enum AutoGLBookingInvoices {
    geniue = 'regularInvoice',
    advance = 'advanceInvoice',
    proforma = 'proformaInvoice',
    proformaDispatched = 'proformaDispatchedInvoice',
}

// export enum AutoGLBookingDocumentTypeName {
//     goodsReceivingNote = 'goodsReceivingNote',
//     goodsDespatchNote = 'goodsDespatchNote',
//     writeOffGoods = 'writeOffGoods',
//     interWarehouseTransfer = 'interWarehouseTransfer',
//     procurementOrder = 'procurementOrder',
//     offerNote = 'offerNote',
//     invoicedStockNote = 'invoicedStockNote',
//     stockReport = 'stockReport',
//     retailPriceCalculation = 'retailPriceCalculation',
//     regularInvoice = 'regularInvoice',
//     advanceInvoice = 'advanceInvoice',
//     proformaInvoice = 'proformaInvoice',
//     proformaDispatchedInvoice = 'proformaDispatchedInvoice',
// }

export enum AutoGLBookingValues {
    grossValue = 'grossValue',
    taxValue = 'taxValue',
    rebateValue = 'rebateValue',
    retailValue = 'retailValue',
    invoicePriceValue = "invoicePriceValue",
    netoPriceValue = "netoPriceValue",
    transportationValue = "transportationValue",
    shippingValue = "shippingValue",
    customsValue = "customsValue",
    otherCostsValue = "otherCostsValue",
    allDependetCosts = "allDependetCosts",
    purchasePriceValue = "purchasePriceValue",
    wholesalePriceValue = "wholesalePriceValue",
    retailPriceValue = "retailPriceValue",
    accommodationTaxValue = 'accommodationTaxValue',
    discountValue = 'discountValue',
}

export const DocumentsForAutoGLBooking = {
    goodsReceivingNote: [
        {
            documentItem: 'grossValue',
            description: 'Total purchase price value',
            glType: 'I',
            glClass: 'P',
        },
        {
            documentItem: 'taxValue',
            description: 'Total tax value',
            glType: 'I',
            glClass: 'P',
        },
    ],
    goodsDespatchNote: [
        {
            documentItem: 'grossValue',
            description: 'Total sales price value',
            glType: 'O',
            glClass: 'P',
        },
        {
            documentItem: 'taxValue',
            description: 'Total tax value',
            glType: 'O',
            glClass: 'P',
        },
    ],
    writeOffGoods: [
        {
            documentItem: 'grossValue',
            description: 'Total sales price value',
            glType: 'O',
            glClass: 'P',
        },
    ],
    interWarehouseTransfer: [
        {
            documentItem: 'grossValue',
            description: 'Total sales price value',
            glType: 'N',
            glClass: 'P',
        },
    ],
    procurementOrder: [
        {
            documentItem: 'grossValue',
            description: 'Total purchase price value',
            glType: 'O',
            glClass: 'P',
        },
    ],
    offerNote: [
        {
            documentItem: 'grossValue',
            description: 'Total sales price value',
            glType: 'O',
            glClass: 'P',
        },
    ],
    invoicedStockNote: [
        {
            documentItem: 'grossValue',
            description: 'Total sales price value',
            glType: 'O',
            glClass: 'P',
        },
    ],
    stockReport: [
        {
            documentItem: 'grossValue',
            description: 'Total sales price value',
            glType: 'N',
            glClass: 'P',
        },
    ],
    retailPriceCalculation: [
        {
            documentItem: 'retailValue',
            description: 'Total retail price value',
            glType: 'I',
            glClass: 'P',
        },
        {
            documentItem: 'retailTaxValue',
            description: 'Total retail tax value',
            glType: 'I',
            glClass: 'P',
        },
        {
            documentItem: 'entryPriceValue',
            description: 'Total entry price value',
            glType: 'I',
            glClass: 'P',
        },
        {
            documentItem: 'entryTaxValue',
            description: 'Total entry tax value',
            glType: 'I',
            glClass: 'P',
        },
    ],
    regularInvoice: [
        {
            documentItem: 'grossValue',
            description: 'Total price value',
            glType: 'O',
            glClass: 'P',
        },
        {
            documentItem: 'baseValue',
            description: 'Base price value',
            glType: 'O',
            glClass: 'P',
        },
        {
            documentItem: 'taxValue',
            description: 'Total tax value',
            glType: 'O',
            glClass: 'P',
        },

        {
            documentItem: 'tax1Value',
            description: 'Total tax1 value',
            glType: 'O',
            glClass: 'P',
        },
        {
            documentItem: 'tax2Value',
            description: 'Total tax2 value',
            glType: 'O',
            glClass: 'P',
        },
        {
            documentItem: 'tax3Value',
            description: 'Total tax3 value',
            glType: 'O',
            glClass: 'P',
        },
        {
            documentItem: 'accommodationTaxValue',
            description: 'Total accommodation tax value',
            glType: 'O',
            glClass: 'P',
        },
        {
            documentItem: 'discountValue',
            description: 'Discount value',
            glType: 'O',
            glClass: 'P',
        },
    ],
    advanceInvoice: [
        {
            documentItem: 'grossValue',
            description: 'Total price value',
            glType: 'O',
            glClass: 'P',
        },
        {
            documentItem: 'baseValue',
            description: 'Base price value',
            glType: 'O',
            glClass: 'P',
        },
        {
            documentItem: 'taxValue',
            description: 'Total tax value',
            glType: 'O',
            glClass: 'P',
        },

        {
            documentItem: 'tax1Value',
            description: 'Total tax1 value',
            glType: 'O',
            glClass: 'P',
        },
        {
            documentItem: 'tax2Value',
            description: 'Total tax2 value',
            glType: 'O',
            glClass: 'P',
        },
        {
            documentItem: 'tax3Value',
            description: 'Total tax3 value',
            glType: 'O',
            glClass: 'P',
        },
        {
            documentItem: 'accommodationTaxValue',
            description: 'Total accommodation tax value',
            glType: 'O',
            glClass: 'P',
        },
        {
            documentItem: 'discountValue',
            description: 'Discount value',
            glType: 'O',
            glClass: 'P',
        },
    ],
    proformaInvoice: [
        {
            documentItem: 'grossValue',
            description: 'Total price value',
            glType: 'O',
            glClass: 'P',
        },
        {
            documentItem: 'baseValue',
            description: 'Base price value',
            glType: 'O',
            glClass: 'P',
        },
        {
            documentItem: 'taxValue',
            description: 'Total tax value',
            glType: 'O',
            glClass: 'P',
        },

        {
            documentItem: 'tax1Value',
            description: 'Total tax1 value',
            glType: 'O',
            glClass: 'P',
        },
        {
            documentItem: 'tax2Value',
            description: 'Total tax2 value',
            glType: 'O',
            glClass: 'P',
        },
        {
            documentItem: 'tax3Value',
            description: 'Total tax3 value',
            glType: 'O',
            glClass: 'P',
        },
        {
            documentItem: 'accommodationTaxValue',
            description: 'Total accommodation tax value',
            glType: 'O',
            glClass: 'P',
        },
        {
            documentItem: 'discountValue',
            description: 'Discount value',
            glType: 'O',
            glClass: 'P',
        },
    ],
    proformaDispatchedInvoice: [
        {
            documentItem: 'grossValue',
            description: 'Total price value',
            glType: 'O',
            glClass: 'P',
        },
        {
            documentItem: 'baseValue',
            description: 'Base price value',
            glType: 'O',
            glClass: 'P',
        },
        {
            documentItem: 'taxValue',
            description: 'Total tax value',
            glType: 'O',
            glClass: 'P',
        },

        {
            documentItem: 'tax1Value',
            description: 'Total tax1 value',
            glType: 'O',
            glClass: 'P',
        },
        {
            documentItem: 'tax2Value',
            description: 'Total tax2 value',
            glType: 'O',
            glClass: 'P',
        },
        {
            documentItem: 'tax3Value',
            description: 'Total tax3 value',
            glType: 'O',
            glClass: 'P',
        },
        {
            documentItem: 'discountValue',
            description: 'Discount value',
            glType: 'O',
            glClass: 'P',
        },
    ],
};

export enum BalanceRuleType {
    'Not selected' = 0,
    'creditMinusDebt' = 1,
    'debtMinutCredit' = 2,
}

export const BalanceRuleName: any = {
    0: '',
    1: 'creditMinusDebt',
    2: 'debtMinutCredit',
};

export interface PricesAndRestrictions {
    closed: number;
    closed_arrival: number;
    closed_departure: number;
    min_stay: number;
    max_stay: number;
    min_stay_arrival: number;
    id_rplan: number;
    id_room: number;
    day: number;
    date: string;
    price: number;
    avail: number;
}

export interface RestrictionsGroupedByRoomRemoteId {
    [key: string]: PricesAndRestrictions[];
}

export interface InvoiceConclusionDetailsModel {
    id?: number;
    plu: number | null;
    name: string | null;
    pluPrice: number | null;
    quantity: number;
    type: string | null;
    subtype: string | null;
    totalValueWithDiscount: number;
    totalValue: number;
    taxTotal: number;
    skuTotal: number;
    dailyPriceAdjustments: number;
    normativeRecapitulations: NormativeRecapitulation[];
    invoiceConclusionId: number | null;
    invoiceType: number;
    group: string;
    posTerminalName: string;
    posTerminalId: number;
}

export interface NormativeRecapitulation {
    name: string;
    quantity: number;
    multiplier: number;
    skuPrice: number;
    totalValue: number;
}

export enum InvoiceConclusionIsPendingEnum {
    concluded = 0,
    pending = 1,
    activePending = 2,
}

export interface InvoiceConclusionDetailsResult {
    invoiced: InvoiceConclusionDetailsModel[];
    proformaDispatched: InvoiceConclusionDetailsModel[];
}

export interface InvoiceConclusionDetailsByPosTerminalId {
    [key: string]: InvoiceConclusionDetailsResult;
}
export interface BlobDocumentModel {
    uuid?: string;
    name: string | null;
    type: string | null;
    category: string | null;
    size: number | null;
    data: any;
}

export interface ValueLabelString {
    value: string;
    label: string;
}

export interface StockLogsWithPosItem extends StockLogModel {
    itemName: string;
    itemPrice: number;
}

export interface ReservationModelForDocScann extends ReservationEditModel {
    Customer: CustomerEditModel | null;
    Company: CompanyEditModel | null;
    RoomInfo: RoomInfoEditModel;
    customers: CustomerEditModel[];
    reservationGuestReferences: ReservationGuestReferenceEditModel[];
}

export interface PaginatedData {
    data: any[];
    dataCount: number;
}

export interface DocumentScannerDeviceModel {
    name: string;
    description: string | null;
    uuid: string | null; //zbog kreiranja
}
export interface ReservationGuestBoard {
    id?: number;
    timestamp: number | null;
    reservationId: number | null;
    roomInfoId: number | null;
    checkInTimestamp: number | null;
    checkOutTimestamp: number | null;
    customerId: number | null;
    firstName: string | null;
    lastName: string | null;
    reservationGuestReferenceId: number | null;
    type: string | null;
    board: string | null;
    posTerminalId?: number;
    roomName?: string; // LEFT JOIN
}
export interface PaginatedDataGLDoc {
    data: GlDocumentSql[];
    dataCount: number;
}

export interface LastCleaningForRooms {
    roomInfoId: number;
    endTimestamp: number;
    taskStatus: number;
}

export interface WhiteListModel {
    id?: number;
    name: string;
    tags: string;
    description?: string | null;
    params?: string | null;
    mfcAccessBitMask: number | null;
    createdAt?: number;
    updatedAt?: number;
}

export interface WhiteListItemWithWhiteList extends WhiteListItemModel {
    WhiteList: WhiteListModel;
}

export interface WhiteListItemModel {
    id?: number;
    name: string;
    code: string;
    description?: string | null;
    params?: string | null;
    enabled: boolean;
    whiteListId: number;
    validToTimestamp: number;
    createdAt?: number;
    updatedAt?: number;
}

export interface HouseholdRefillSetupModel {
    id?: number;
    skuId?: number;
    posItemId: number | null;
    quantity: number;
    roomType: number;
    reservationStatus: string | null;
    guestsNumber: number | null;
    warehouseId: number | null;
    type: number;
}

export interface WarehouseItemModel {
    id?: number;
    posItemId: number | null;
    skuId: number | null;
    type: number;
    warehouseId: number | null;
    autoSuggestWriteOff: boolean;
    laundryDispatched: boolean;
    skuPluRates: string | SkuPLURateModel[];
    posItemName?: string; // Left join
}

export interface AgregatedWatehousePosItems extends HouseholdRefillSetupModel {
    autoSuggestWriteOff: boolean;
    laundryDispatched: boolean;
    type: number;
}
export enum ClientTypeEnum {
    company = 1,
    customer = 2,
}
export interface SkuPLURateModelExtended extends SkuPLURateModel {
    name: string;
}

export interface RoomEventTicketModel {
    id?: number;
    category: string | null;
    ruleName: string;
    openingTimestamp: number;
    closingTimestamp: number | null;
    notes?: string | null;
    openingRoomIOChangesRawId?: number | null;
    closingRoomIOChangesRawId?: number | null;
    openingRoomEventRawId?: number | null;
    closingRoomEventRawId?: number | null;
    roomInfoId: number;
    reservationId?: number | null;
    customerId?: number | null;
    remoteDeviceId: string;
}

export interface RoomCardCodeModel {
    id?: number;
    cardCode: number;
    cardCodeValidTo: number;
    type: string;
    params?: string;
    roomInfoId: number;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface TableLayoutWithTable extends TablesLayoutModel {
    Table: TableModel;
}

export interface ResGuestRefWithCustomerSql extends ReservationGuestReferenceEditModel {
    firstName: string;
    lastName: string;
    gender: string;
    dateOfBirth: Date | null;
    birthTown: string | null;
    phoneOrMobile: number | null;
    email: string | null;
    nationality: string | null;
    country: string | null;
    birthCountry: string | null;
    town: string | null;
    warningNote?: string | null;
    resCheckInTimestamp: number;
    resCheckOutTimestamp: number;
    guestCheckedIn?: boolean;
    resGuestRefId: number;
}

export const predefinedGLTypeAndClasses = {
    inboundDocument: 'inboundDocument',
    outboundDocument: 'outboundDocument',
    internalDocument: 'internalDocument',
    financialVoucher: 'financialVoucher',
    otherDocuments: 'otherDocuments',
    bankStatements: 'bankStatements',
    cashRegister: 'cashRegister',
    foreignCurrencyDocuments: 'foreignCurrencyDocuments',
    incomingAccounts: 'incomingAccounts',
    outgoingAccounts: 'outgoingAccounts',
};

export interface InvoiceModelWithSequelize extends InvoiceModelWithInvoiceItems {
    save: () => Promise<void>;
    update: (values: Partial<InvoiceModelWithInvoiceItems>) => Promise<void>;
    // Add other Sequelize methods as needed
}